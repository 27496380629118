.services{
    
    padding-top: 60px;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg,#636266, #1a1826);
}

.webImage{
    width: 298px;
    height: 193px;
    border: 1px solid black; 
    box-shadow: 0px 0px 5px black; 
    height: auto; 
    margin-top: 10px; 
    margin-right: 10px;
}

.services p{
    color: #f1f1f1;
    text-align: center;
    font-size: 2.8em;
    padding-bottom: 90px;
    padding-top: 10px;
}
.services h1{
  color: #f1f1f1;
  text-align: center;
  font-size: 2.5em;
  padding-top: 70px;
  padding-bottom: 60px;
}
.services h2{
  color: #000000;
  text-align: center;
  font-size: 1.5em;
  padding-top: 90px;
  padding-bottom: 100px;
}
@media (min-width: 800px) {
    .services p {
      font-size: 3.5em;
    }
    .webImage {
      width: 500px;
      height: 300px;
    }
  }
  