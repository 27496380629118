.home{
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #1a1826, #636266);
    padding: 20px 20px 200px;
    align-items: center;
}
.titleContainer{
    padding-top: 80px;
}
.titleContainer2{
    padding-top: 90px;
}

.home P{
    color: white;
    font-size: 2em;
}
.callToAction{
    background-color: rgb(36, 179, 36);
    padding: 10px;
    margin-left: 20px;
    transition: 0.5s;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1.5em;
}

.callToAction:Hover{
    padding: 15px;
    cursor: pointer;
}

.ctaContainer{
    padding-top: 40px;
}
.whatsapp {
    padding: 10px;
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
  }
  .webImage{
    width: 900px;
    height: 300px;
    padding-bottom: 80px;
}