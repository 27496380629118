.howWeWork {
    display: flex;
    padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column;
    background: linear-gradient(180deg, #1a1826, #636266);
  }
  
  .title {
    font-size: 3.5em;
    color: #f1f1f1;
    font-weight: 400;
    text-align: center;
  }
  