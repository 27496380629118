.navBar{
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    background-color: black;
    color: white;   
}

.navOpen{
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    color: white;
    flex-direction: column;
    align-items: center;
}
.navLink{
    display: flex;
    font-size: 2em;
    font-weight: 400;
    color: white;
    padding-top: 90px;

}
.navLink:hover{
    cursor: pointer;
}

/*.border{
    display: flex;
    height: 1px;
    animation-name: animacionborde;
    animation-duration: 1s;
    background-color:rgb(199, 33, 33)
}*/

@keyframes animacionbordes{
    0%{
        height: 0px;
    }
    100%{
        height: 1px;
    }
}

.logo{
    display: flex;
    color:white;
    font-weight: 600;
}

@media(min-width : 800px){
    .logo{
        font-size: 2em;
    }
    .navBar{
        align-items: center;
        align-items: center;
        justify-content: space-evenly;
        height: 50px;
    }
    .linksContainer{
        display: flex;
        width: 50%;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .navLink{
        font-weight: 400;
        padding-top: 4px;
        font-size: 1.4em;
        transition: 0.5s;
    }
    .navLink:hover{
        font-size: 1.5em;
    }

    .navOnScroll{
        align-items: center;
        align-items: center;
        justify-content: space-evenly;
        height: 50px;
        background-color: #4a4564;
    }
}

.navOnScroll{
    background-color: #000000;
    display: flex;
    height: 50px;
    width: 100%;
    position: fixed;
    justify-content: space-around;
    align-items: center;
}
.ContactLink{
    color: #f1f1f1;
    font-weight: 400;
    font-size: 1.5em;
    transition: 0.5s;
    border-radius: 4px;
    background-color: #f73823;
    padding: 3px;
    cursor: pointer;
}
.webImage{
    width: 298px;
    height: 193px;
    padding-bottom: 80px;
}