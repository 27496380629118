.benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg,#636266, #1a1826);
    padding-bottom: 90px;
  }
  
  .benefits p {
    color: #f1f1f1;
    padding-top: 20px;
    font-size: 1.7em;
  }
  
  .benefitTitle {
    font-size: 3.5em;
    font-weight: 400;
    color: #f1f1f1;
  }
  